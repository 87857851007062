
import { addCategory, deleteCategory, getCategories, updateCategory } from '@/api/category';
import { ref } from 'vue';
import { useStore } from 'vuex';
import { ElMessage } from 'element-plus';
export default {
    setup(){
      const store = useStore();

      let resetSearch = false;

      const table:any = ref({
          page: 1,        // 当前在第几页
          pageSize: 10,   // 一页显示多少
          total: 0,       // 数据总量
          loading: true,  // 加载中
          data: [],       // 表格数据
          isMobile: false,// 表格是否移动端
      });
      
      const btnLoading = ref(false);

      const categoryForm = ref({
          categoryName: '',
          id: 0,
      });

      const dialogFormVisible = ref(false);

      const editDialogFormVisible = ref(false);

      const searchText = ref("");

      table.value.isMobile = store.state.app.isMobile;
      
     getCategories({page: table.value.page,pageSize: table.value.pageSize}).then((res: any) => {
        table.value.total = res.data.total;
        table.value.data = res.data.list;
        table.value.loading = false;
    });
    
    const handleSizeChange = (pageSize) => {
        table.value.loading = true;
        table.value.pageSize = pageSize;
        getCategories({page: table.value.page,pageSize: table.value.pageSize}).then((res: any) => {
          table.value.total = res.data.total;
          table.value.data = res.data.list;
          table.value.loading = false;
        });
    }

    const handleCurrentChange = (page) => {
        table.value.loading = true;
        table.value.page = page;
        getCategories({page: table.value.page,pageSize: table.value.pageSize}).then((res: any) => {
          table.value.total = res.data.total;
          table.value.data = res.data.list;
          table.value.loading = false;
        });
    }

    const handleAddClick = () => {
        categoryForm.value.categoryName = "";
        dialogFormVisible.value = true;
    }

    const handleEditClick = (row) => {
        categoryForm.value.categoryName = row.categoryName;
        categoryForm.value.id = row.id;
        editDialogFormVisible.value = true;
    }

    const handleAddCategory = () => {
      if(categoryForm.value.categoryName.trim() == ""){
        ElMessage({
          message: '分类名称不能为空!',
          type: 'error',
        });
        return;
      }else{
        dialogFormVisible.value = false;
        btnLoading.value = true;
        addCategory(categoryForm.value.categoryName).then((res) => {
          btnLoading.value = false;
          if(res.data){
            table.value.loading = true;
            getCategories({page: table.value.page,pageSize: table.value.pageSize}).then((res: any) => {
              table.value.total = res.data.total;
              table.value.data = res.data.list;
              table.value.loading = false;
            });
            ElMessage({
              message: '添加分类成功!',
              type: 'success',
            });

          }
        })

      }
    }


    const handleEditCategory = () => {
      if(categoryForm.value.categoryName.trim() == ""){
        ElMessage({
          message: '分类名称不能为空!',
          type: 'error',
        });
        return;
      }else{
        editDialogFormVisible.value = false;
        btnLoading.value = true;
        updateCategory(categoryForm.value.id,categoryForm.value.categoryName).then((res) => {
          btnLoading.value = false;
          if(res.data){
            table.value.loading = true;
            getCategories({page: table.value.page,pageSize: table.value.pageSize}).then((res: any) => {
              table.value.total = res.data.total;
              table.value.data = res.data.list;
              table.value.loading = false;
            });
            ElMessage({
              message: '修改分类成功!',
              type: 'success',
            });
            categoryForm.value.categoryName = "";
          }
          
        });
      }
    }

    const handleDelCategory = (id) => {
        deleteCategory(id).then((res: any) => {
            if(!res.errCode) {
              ElMessage.success("删除成功!");
              table.value.loading = true;
              getCategories({page: table.value.page,pageSize: table.value.pageSize}).then((res: any) => {
                table.value.total = res.data.total;
                table.value.data = res.data.list;
                table.value.loading = false;
              });
            }else{
              ElMessage.error(res.detail);
            }

        });
    }

    const searchCategories = () => {
        if(searchText.value.trim() != ""){
          resetSearch = false;
          table.value.loading = true;
          getCategories({page: table.value.page,pageSize: table.value.pageSize,categoryName: searchText.value}).then((res: any) => {
          table.value.total = res.data.total;
          table.value.data = res.data.list;
          table.value.loading = false;
        });

        }else if(!resetSearch){
          table.value.loading = true;
          getCategories({page: table.value.page,pageSize: table.value.pageSize,categoryName: searchText.value}).then((res: any) => {
            table.value.total = res.data.total;
            table.value.data = res.data.list;
            table.value.loading = false;
            });
            resetSearch = true;
        }

    }

      return {
        table,
        handleSizeChange,
        handleCurrentChange,
        handleAddClick,
        dialogFormVisible,
        editDialogFormVisible,
        categoryForm,
        handleAddCategory,
        handleDelCategory,
        handleEditCategory,
        btnLoading,
        handleEditClick,
        searchCategories,
        searchText,
      }
    }
}
